header {
  background: transparent;
}

.navbar-expand-md .navbar-brand .navbar-brand-item {
  height: auto;
}

.h1 .healthAddicts {
  font-size: 6rem;
}

.health,
.algo {
  font-size: 7.5rem;
}

/*cirble animation */
.circle-f-lottie {
  position: relative;
  /* left: 56.5vw;
    bottom: 12.1vw; */
  z-index: 3;
  width: 5vw;
  -webkit-transform: rotate(4deg) translate(0px, 60%);
  -ms-transform: rotate(4deg) translate(0px, 60%);
  transform: rotate(4deg) translate(0px, 60%);
}

.circle-f-lottie path,
.line-f-lottie-holder path {
  fill: #fe5000;
}

.line-f-lottie-holder._2 {
  /* right: 12vw; */
  left: 56.5vw;
  bottom: -1.9rem;
  width: 20vw;
  -webkit-transform: translate(50%, 0px);
  -ms-transform: translate(50%, 0px);
  transform: translate(50%, 0px);
}

.line-f-lottie-holder {
  position: absolute;
  left: 56.5vw;
  /* right: 12vw; */
  bottom: -2.5rem;
  z-index: 3;
  width: 11rem;
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}

.signature {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 0;
  padding-bottom: 55.30973%;
}

.signature svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

path {
  stroke-dashoffset: 600;
  stroke-dasharray: 600;
  animation: draw 1s linear forwards;
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.health {
  padding-right: 1rem;
}

.circuloRevisamos svg {
  position: absolute;
  width: 225px;
  height: 54px;
  transform: translate3d(-156px, -7px, 0px);
}

.handwriting,
.handwriting-ca {
  font-family: "TrashHand", cursive;
  color: #fe5000;
  position: absolute;
  top: 4.4rem;
  left: 24.7rem;
  opacity: 0;
  font-size: 1.5em;
  white-space: nowrap;
  /*Evitar saltos de línea*/
  overflow: hidden;
  width: 30em;
}

.handwriting-ca {
  left: 24.5rem;
}

.handwritingAnimation {
  animation: keyframes 5s steps(300) 1 forwards;
  animation-delay: 2s;
}

@keyframes keyframes {
  from {
    width: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 1;
  }
}

.ydeVerdad {
  top: -7em;
  left: 24rem;
  position: relative;
  opacity: 0;
  animation: showVerdad 0s 2s forwards;
}
@keyframes showVerdad {
  to {
    opacity: 1;
  }
}

.ydeVerdad p {
  font-family: "TrashHand", cursive;
  color: #fe5000;
  font-size: 1.5em !important;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: auto 0; /* Gives that scrolling effect as the typing happens */
  letter-spacing: -0.8px; /* Adjust as needed */
  animation: typing 10.5s steps(30, end);
  animation-delay: 2s;
}
/* The typing effect */
@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.preFootLinks {
  font-family: "sf pro", sans-serif;
  color: #1d1d1b;
  font-size: 1.85rem;
  letter-spacing: calc(-10 / 1000) * 1em;
}

.textoMainHome p {
  font-size: 1.375rem;
}

/* Anchor image gradient overlay*/

.contendorImages1 {
  position: absolute;
  /* top: 0;
    left: 10%; */
  animation-duration: 3s;
}

.btn-img {
  position: relative;
  /* display: inline-block; */
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 3px;
}

.btn-img:active {
  opacity: 0.3;
  transition: opacity 0.1s;
}

.btn-img-gradient:hover::before,
.btn-img-combo:hover::before {
  opacity: 1;
  transition: opacity 0.3s;
}

.btn-img-gradient::before,
.btn-img-combo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 99;
  background-image: linear-gradient(to bottom, transparent 0%, #fd5204 100%);
  opacity: 0;
  transition: opacity 0.3s;
}

.scrollDown {
  position: absolute;
  right: calc(100vw - 90%) !important;
}

.healthAddicts {
  position: relative;
  overflow: visible;
  letter-spacing: -0.05em;
}
.underlined,
.underlined2 {
  position: absolute;
  bottom: 0.1rem;
  height: 3.8rem;
  background-image: url(../images/addictsUnderline.png);
  background-position: -2rem 3rem;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  overflow: visible;
  background-repeat: no-repeat;
}
.underlined2 {
  bottom: 30%;
  left: 1rem;
  height: 4.8rem;
  overflow: hidden;
}

.showUnderline {
  background-size: 100% 64%;
}
.underlined--thick {
  background-position: 0 1.6rem;
}

.underlined2--thick {
  background-position: 0em 3.2rem;
}

.imagesHome {
  min-height: 80vh;
  color: var(--color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  & p {
    font-family: "sf pro light", sans-serif;
    font-size: 1.6785rem;
  }
}

button.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  width: 24rem;
  height: auto;
  font-family: "sf pro";
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  background: #ff5001;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 2rem;
  height: 0rem;
  background: none;
}
button.learn-more .circle .icon.arrow:before {
  position: absolute;
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url(../images/whatsBtn.png);
  top: -0.95rem;
  right: -0.2rem;
}
button.learn-more .button-text {
  //   transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0.3rem;
  margin: -14px 0 0 1.8rem;
  color: #ff5001;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  font-size: 1.6785rem;
  //   text-transform: uppercase;
}
a:hover .circle,
button:hover .circle {
  width: 100%;
}
a:hover .circle .icon.arrow,
button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(0.3rem, 0);
}
a:hover .button-text,
button:hover .button-text {
  color: #fff;
}

.scroll-parent {
  position: relative;
  width: 200vw;
  //   height: 20rem;
  padding: 2rem 0rem;
  overflow-x: hidden;
}

.scroll-element {
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.scroll-element img {
  width: 100%;
  //   padding-right: 4rem;
}

.primary {
  animation: primary 70s linear infinite;
}

.secondary {
  animation: secondary 70s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes secondary {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}
.saludableCricleA {
  width: 110%;
  margin-right: 1.5rem;
}

.revisamosCricle {
  width: 110%;
  margin-right: 1.5rem;
}

.saludableCricleA svg {
  position: absolute;
  top: -5px;
  left: -30px;
  width: 110%;
  height: 115%;
  pointer-events: none;
  //   transform: rotate(180deg);
}
.revisamosCricle svg {
  position: absolute;
  top: 0px;
  left: -5px;
  width: 115%;
  height: 130%;
  pointer-events: none;
  //   transform: rotate(180deg);
}

.revisamosCricle path,
.saludableCricleA path {
  stroke: #ff5001;
  stroke-width: 7px;
  stroke-dasharray: 0 1900;
}
.saludableCricleAnimation path {
  animation-delay: 12s;
  animation: draw2 2.5s forwards;
  // transition: stroke-dashoffset 0.5s cubic-bezier(0.29, 0.68, 0.74, 1.02);
}

@keyframes draw2 {
  from {
    stroke-dasharray: 0 1900;
  }

  to {
    stroke-dasharray: 1900 1900;
  }
}

.circle1 {
  transform: "matrix(3.0199999809265137,0,0,3.0199999809265137,23.216033935546875,8.347564697265625)";
}
.img--carrousel-container {
  width: 110px;
}

a#conoceMas {
  width: 25.5rem;
  height: auto;
}
a#conoceMas.verProyecto .button-text {
  margin-top: -1rem;
  font-size: 1.5rem;
}

@media screen and (min-width: 1900px) {
  .parallax-container {
    min-height: 61vh;
    // background: url(../images/aLogo.png) no-repeat center -220px,
    //   // linear-gradient(to bottom, #ffffff, #ddd);
    //   linear-gradient(90deg, #e9efee 44.31%, #f3f7f7 73.26%),
    //   url(../images/aLogo.png),
    //   linear-gradient(-90deg, #fff 20.2438%, #e7e7e7 74%, #ddd 100%);
    //   linear-gradient(
    //       to bottom,
    //       rgba(255, 255, 255, 1) 20%,
    //       rgb(233, 239, 238) 100%
    //     );
    // linear-gradient(
    //     to bottom,
    //     rgba(255, 255, 255, 1) 0%,
    //     rgba(231, 231, 231, 1) 44%,
    //     rgba(221, 221, 221, 1) 100%
    //   );
    background-attachment: fixed;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1580px) {
  #flecha {
    transform-origin: center;
    position: relative;
    top: -4px;
    left: -210px;
    transform: rotate(270deg);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1890px) {
  .parallax-container {
    min-height: 71vh;
  }
  #flecha {
    transform-origin: center;
    position: relative;
    top: -7px;
    // left: -469px;
    transform: rotate(270deg);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  body {
    min-height: 75vw;
    color: --default-font-color;
    background: linear-gradient(#fff 0%, #e7e7e7 74%, #ddd 100%);
    background-image: url(../images/aLogo.png),
      linear-gradient(#fff 0%, #e7e7e7 74%, #ddd 100%);
    background-position: 50% -68px, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 150%;
    background-attachment: fixed;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: 0;
    font-family: "sf pro", sans-serif;
  }

  .parallax-container {
    min-height: 78vh !important;
  }

  h1 > span,
  .h1 > span {
    margin-top: 3rem;
    font-size: 4.5rem;
  }

  .h1 {
    font-weight: 500;
    font-size: 4.5rem;
    margin-bottom: 4rem;
  }

  .health,
  .algo {
    font-size: 5.5rem !important;
    display: block;
  }
  .underlined {
    bottom: -9.5rem;
  }

  .textoMainHome p {
    font-size: 16px;
  }

  .ydeVerdad p {
    font-size: 1.1em !important;
  }
  .textoMainHome {
    margin-top: 15vh;
  }

  .circuloRevisamos svg {
    position: absolute;
    width: 250px;
    height: 38px;
    transform: translate3d(-156px, -7px, 0px);
  }

  .handwriting,
  .handwriting-ca {
    top: 2.6rem;
    left: 19.4rem;
    opacity: 0;
    font-size: 1.1em;
  }
  .handwriting-ca {
    left: 20.4rem;
  }
  a#conoceMas {
    width: 15.5rem;
    height: auto;
  }

  #flecha {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: absolute;
    .draw-arrow {
      stroke-width: 5;
      stroke: #000;
      fill: none;
      stroke-dasharray: 300;
      stroke-dashoffset: 300;
      animation-delay: 5s;
      animation-fill-mode: forwards;
      animation-name: drawFlecha;
      animation-duration: 3s;

      &.tail-1 {
        animation-delay: 5.5s;
      }
      &.tail-2 {
        animation-delay: 5.7s;
      }
    }
  }
  @keyframes drawFlecha {
    to {
      stroke-dashoffset: 0;
    }
  }

  #flecha {
    transform: rotate(270deg);
    transform-origin: center;
    position: relative;
    top: 0px;
    left: -228px;
  }

  #flecha {
    opacity: 1;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    top: 40px;
    left: -300px;
  }
  .scroll-parent {
    width: 600vw;
  }

  button.learn-more {
    width: 16rem;
    height: auto;
    font-family: "sf pro";
  }

  button.learn-more .button-text {
    //   transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0.3rem;
    margin: -0.2rem 0 0 1.8rem;
    color: #ff5001;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    font-size: 13px;
    //   text-transform: uppercase;
  }
  a.verProyecto .button-text {
    font-size: 14px !important;
  }
  a#conoceMas.verProyecto .button-text {
    margin-top: -0.35rem;
  }

  .imagesHome p {
    font-size: 0.75rem;
  }

  .aspidLogo img#circlePremio {
    width: 80px;
    height: 80px;
  }
  #elLogo {
    z-index: 1;
    position: absolute;
    width: 50%;
    top: 38%;
    left: 27%;
  }

  .underlined2 {
    bottom: 45%;
    left: 0.2rem;
    height: 3rem;
    overflow: hidden;
  }

  .underlined2--thick {
    background-position: 0em 2rem;
  }

  .saludable--container {
    margin-top: -0.5rem;
  }

  .saludableCircle {
    transform: scale(0.25) translate3d(-152%, -114%, 0);
  }
  .saludableCricleA {
    margin-right: 0.5rem;
  }
  .saludableCricleA svg {
    width: 110%;
    height: 110%;
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: -7px;
  }
  .de--container {
    margin-left: -0.8rem;
  }
  .tu--container {
    margin-top: -0.5rem;
  }
  .ydeVerdad {
    top: -6em;
    left: 18.8rem;
  }
  .carousel-item img {
    // width: 4.75rem !important;
    width: 40px !important;
  }

  .enClass {
    position: relative;
    bottom: 0.9rem;
    margin-left: 0rem;
  }

  .loMasClass {
    margin-top: -1.5rem;
  }
  .img--carrousel-container {
    width: 60px;
    margin-left: 0.5rem;
  }
  #projectRewards2 {
    margin-top: 0.5rem;
  }
}

@media only screen and (min-width: 546px) and (max-width: 820px) {
  a.nav-link {
    font-size: 0.6rem;
  }
  .parallax-container {
    min-height: 55vh;
  }
  .h1 {
    color: var(--default-font-color);
    font-family: sf pro, sans-serif;
    font-size: 4.5rem;
    font-weight: 500;
  }
  h1 span,
  .h1 span {
    font-size: 5rem;
  }
  .health,
  .algo {
    font-size: 5.5rem !important;
  }
  .underlined {
    bottom: -0.5rem;
  }
  .underlined2 {
    bottom: 2.5rem;
  }
  .underlined--thick {
    background-position: 0 0.9rem;
  }
  .textoMainHome p {
    font-size: 0.94rem;
  }

  button.learn-more {
    width: 14rem;
    font-family: "sf pro";
  }

  button.learn-more .button-text {
    color: #ff5001;
    text-align: center;
    margin: -14px 0 0 1.8rem;
    padding: 1.1rem 0.3rem;
    font-size: 0.9rem;
  }
  button.learn-more .circle {
    width: 2rem;
    height: 2rem;
  }

  button.learn-more .circle .icon.arrow {
    width: 2rem;
    height: 0;
    background: none;
    left: 0.25rem;
  }
  .scrollDown {
    height: 12%;
  }

  .ydeVerdad {
    top: -5.5em;
    left: 16rem;
  }
  .ydeVerdad p {
    font-size: 1em !important;
  }
  #flecha {
    top: -10px;
    left: -135px;
  }
  .imagesHome {
    min-height: 48vh;
    & p {
      font-family: sf pro light, sans-serif;
      font-size: 1rem;
    }
  }
  .aspidLogo {
    margin-top: 1rem;
  }

  .preFootLinks {
    color: #1d1d1b;
    letter-spacing: -0.08rem;
    font-family: sf pro, sans-serif;
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 771px) and (max-width: 1200px) {
  .textoMainHome p {
    font-size: 0.9rem;
  }
  .ydeVerdad {
    top: -5em;
    left: 15.5rem;
  }
  #flecha {
    top: 0;
    left: -180px;
  }
  .imagesHome p {
    font-family: sf pro light, sans-serif;
    font-size: 1.0785rem;
  }
}

@media only screen and (min-width: 546px) and (max-width: 820px) {
  footer a,
  footer p {
    color: #fff;
    font-family: sf pro, sans-serif;
    font-size: 0.8rem;
    text-decoration: none;
    position: relative;
  }
  .textoMainHome p {
    font-size: 0.86rem;
  }
  .ydeVerdad {
    top: -5em;
    left: 14.5rem;
  }
}
